@font-face {
    font-family: "M Plus Light";
    src: url("../../assets//fonts/MPLUS1p-Light.ttf");
}

@font-face {
    font-family: "M Plus Regular";
    src: url("../../assets/fonts/MPLUS1p-Regular.ttf");
}

@font-face {
    font-family: "M Plus Bold";
    src: url("../../assets/fonts/MPLUS1p-Bold.ttf");
}

.card-image {
    min-width: 250px;
    max-width: 280px;
}

.card-container {
    min-width: calc(100% / 3);
    min-height: 560px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.3);
    margin: 1rem 1rem;
}

.card-title h2 {
    font-family: "M Plus Light";
    text-transform: uppercase;
    font-size: 24px;
    background-color: #DBD8B6;
    padding: 10px;
    margin-top: 1rem;
    color: #348759;
    text-align: center;
}

.card-title-bold {
    font-family: "M Plus Bold" !important;
}

.card-list {
    list-style: none;
}

.card-list li {
    margin-left: -25px !important;
    color: #348759;
    text-align: center;
}

.card-button {
    border: none;
    background-color: #348759;
    padding: 1rem;
    text-transform: uppercase;
    color: #DBD8B6;
    font-family: "M Plus Regular";
    transition: background-color 1s;
    text-decoration: none;
    text-align: center;
}

.card-button:hover {
    background-color: #FE8483;
    color: #DBD8B6;
}

/* @media screen and (max-width: 768px) and (min-width: 712px) {
    .card-container {
        margin: 1rem 1rem;
    }
}

@media screen and (min-width: 1024px) {
    .card-container {
        margin: 1rem 1rem;
    }
} */