@font-face {
    font-family: "M Plus Light";
    src: url("../../assets/fonts/MPLUS1p-Light.ttf");
}

.divider {
    width: 100px;
    height: 5px;
    background-color: rgb(251,176,59);
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.galeria-header {
    width: 100%;
    height: 680px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.galeria-title {
    color: rgba(255, 255, 255, 0.9);
    font-family: "M Plus Light";
    text-transform: uppercase;
    letter-spacing: 1rem;
    font-size: 24px;
    text-align: center;
    margin-bottom: 2rem;
}

.galeria-subtitle {
    color: rgba(255, 255, 255, 0.9);
    font-family: "M Plus Light";
    text-transform: uppercase;
    letter-spacing: .2rem;
    font-size: 12px;
    text-align: center;
    padding: 0 2rem;
}

.galeria-container {
    width: 100%;
    min-height: 420px;
    background-color: #605644;
    border-top: 10px solid #908066;
    border-bottom: 10px solid #908066;
    background-image: url("../../assets/imagens/bg-logo.png");
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    overflow-x: hidden;
}

.galeria-title2 {
    font-family: "M Plus Light";
    color: rgb(199,178,153);
    letter-spacing: .8rem;
    text-transform: uppercase;
    margin: 2rem;
    text-align: center;
}

@media screen and (max-width: 768px) and (min-width: 712px) {
    .galeria-title {
        font-size: 42px;
    }

    .galeria-subtitle {
        font-size: 14px;
    }
}

@media screen and (min-width: 1024px) {
    .galeria-title {
        font-size: 42px;
    }

    .galeria-subtitle {
        font-size: 14px;
    }
}