@font-face {
    font-family: "M Plus Light";
    src: url("../../assets/fonts/MPLUS1p-Light.ttf");
}

.footer-container {
    width: 100%;
    height: 280px;
    background-color: #24352C;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "M Plus Light";
    color: #BDB299;
    font-size: 13px;
    text-align: center;
    padding: 2rem;
}

.shadow-top {
    width: 100%;
    height: 280px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
}