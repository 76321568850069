@font-face {
    font-family: "M Plus Medium";
    src: url("../../assets/fonts/MPLUS1p-Medium.ttf");
}

.navbar-top {
    padding: 0 8rem!important;
    width: 100%!important;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    position: fixed;
    top: 0;
    left: 0;
    background-image: linear-gradient(to right, #2CD281,  #9B9B75);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    z-index: 5;
}

.navbar-link {
    text-transform: uppercase;
    padding: 1.5rem 1rem;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.9);
    transition: background-color 1s;
    letter-spacing: 2px;
    font-size: 13px;
    text-align: center;
}

.navbar-link-dropdown {
    font-family: "M Plus Medium";
    padding: .6rem 1rem !important;
    text-transform: uppercase;
    color: #888!important;
}

.navbar-link:hover {
    background-color: #FE8483;
    color: white;
}

.active-link {
    border-bottom: 5px solid #68FFBB;
}

.active-link-left-border {
    border-left: 5px solid #68FFBB;
    padding: 0!important;
    background-color: rgba(0, 104, 67, 0.4);
}

.active-link-left-border:active {
    background-color: rgba(255, 255, 255, 0.4) !important;
}

.active-link-left-border a {
    color: #fff!important;
    padding: .6rem .6rem !important;
}

.active-link:hover {
    background-color: transparent;
}

.dropdown-button {
    background-color: transparent;
    border: 1px solid white;
    padding: .6rem .6rem;
    border-radius: .5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-item:active {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

@media screen and (max-width: 1020px) and (min-width: 320px) {
    .navbar-top {
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        padding: 0 .6rem !important;
    }

    .navbar-link {
        display: none;
    }

    .dropdown-button:active {
        background-color: rgba(255, 255, 255, 0.4);
    }

    .brand-image {
        position: absolute !important;
        left: 0;
        top: 0;
        width: 120px;
        height: auto;
    }
}

@media screen and (min-width: 1024px) {
    .navbar-top {
        padding: 0 2rem !important;
    }

    .navbar-link {
        display: flex;
    }

    .dropdown-button {
        display: none;
    }

    .brand-image {
        display: none;
    }
}